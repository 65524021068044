/*
 * @Author: yangzonglong
 * @Date: 2020-05-18 09:48:18
 * @version: v1.0.0
 * @Descripttion: 通用server
 * @LastEditors: juwenzheng 3225460988@qq.com
 * @LastEditTime: 2022-07-06 09:05:57
 * @Auditor: 
 */
import axios, { AxiosRequestConfig as DefaultAxiosRequestConfig } from 'axios';
import store from '@redux/store';
import { error } from '@utils/message/message';
import { Types } from '@redux/commonReducer';
interface AxiosRequestConfig extends DefaultAxiosRequestConfig {
  firstMessage?: boolean
}

export const TIMEOUT = 1000 * 60 * 2; // 默认2分钟超时
export const METHOD = 'POST';

const showLoading = () => store.dispatch({ type: Types.SETLOADING, loading: true });
const hideLoading = () => store.dispatch({ type: Types.SETLOADING, loading: false });

export async function serverLoading(url: string, data: Record<string, any>, options?: AxiosRequestConfig) {
  return await server(url, data, options, true)
}

export default async function server(url: string, data: Record<string, any>, options?: AxiosRequestConfig, loading?: boolean) {
  const _loading = typeof options === 'boolean' ? options : loading;

  try {
    if(_loading) showLoading();
    const result = await axios(mergeOptions(url, data, options));
    if(_loading) hideLoading();

    if (result.status === 200) { // http
      const data = result.data;
      if (data.status === 200) return data.result;

      const message = data.message || {};

      if (message.code === -11 && process.env.REACT_APP_ENV !== 'dev') { // 登录失效
        // window.$app.showIsLoginFailure();
      }

      // eslint-disable-next-line
      throw { message: message.message, code: message.code }
    }
  } catch (err) {
    const message = typeof err === 'string' ? err : err?.message;
    if(_loading) hideLoading();

    if(options?.firstMessage) error(message || err?.code);
    else error(err?.code || message);

    throw err
  }
}

export function mergeOptions(url: string, data: Record<string, any>, options?: AxiosRequestConfig) {
  const _options = options || {};
  _options.method = _options.method || METHOD;
  _options[_options.method === 'GET' ? 'params' : 'data'] = data;
  _options.url = url;
  _options.timeout = TIMEOUT;
  return _options;
}