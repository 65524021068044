/*
 * @Author: yangzonglong
 * @Date: 2021-06-16 14:31:47
 * @version: v1.0.0
 * @Descripttion: 登录失效提示
 * @LastEditors: yangzonglong
 * @LastEditTime: 2021-06-25 10:05:42
 * @Auditor: 
 */
import  { memo } from 'react'
import { Modal, Button } from 'antd'

const boxstyle: any = {
  textAlign: 'right'
}

export default memo(() => {

  const toLogin = () => {
    window.sessionStorage.clear();
    window.top.location.href = '/core/user/login'
  }

  return (
    <Modal 
      width={480} visible closable={false} title='超时登出' 
      footer={<div style={boxstyle}><Button onClick={toLogin}>关闭</Button></div>}>
      您已30分钟未操作，平台为保护您的数据安全已自动退出，请重新登录。
    </Modal>
  )
})