/*
 * @Author: yangzonglong
 * @Date: 2021-08-09 15:21:18
 * @version: v1.0.0
 * @Descripttion: 询问是否可跳转路由，关闭窗口
 * @LastEditors: yangzonglong
 * @LastEditTime: 2021-08-09 15:36:36
 * @Auditor: 
 */
type QuitInquiryProps = undefined | Function;

let _quitInquiry: QuitInquiryProps;

function beforeunload(e: any) {
  let _e = e || window.event;

  if (_quitInquiry) {
    const result = _quitInquiry();

    if (!result) { // 返回false, 子项目有未完成项，询问是否离开
      _e.returnValue = '是否离开';
      return _e.returnValue;
    }
  }
}

export function setQuitInquiry(fn?: Function) {
  _quitInquiry = fn;
}

export function quitInquiry() {
  if (_quitInquiry) {
    const result = _quitInquiry();
    return result
  }
  return true // 可离开
}

window.addEventListener('beforeunload', beforeunload);


