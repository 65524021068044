/*
 * @Author: yangzonglong
 * @Date: 2021-03-25 09:46:00
 * @version: v1.0.0
 * @Descripttion: 配置文件
 * @LastEditors: juwenzheng 3225460988@qq.com
 * @LastEditTime: 2022-07-05 15:47:17
 * @Auditor: 
 */
import { lazy } from 'react';

const Workbench = lazy(() => import('./pages/workbench/Workbench'));
const App = lazy(() => import('./pages/app/App'));
const Login = lazy(() => import('./pages/user/login/Login'));
const Register = lazy(() => import('./pages/user/register/Register'));
const ResetPwd = lazy(() => import('./pages/user/resetPwd/ResetPwd'));
const AgreementCn = lazy(() => import('./pages/user/agreement/Cn'));
const AuditStatus = lazy(() => import('./pages/auditStatus/AuditStatus'));
const EditInfo = lazy(() => import('./pages/auditStatus/editInfo/EditInfo'));

export const routers = [
  { path: '/workbench', component: Workbench },
  { path: '/app', component: App },
  { path: '/user/login', component: Login },
  { path: '/user/signup', component: Register }, 
  { path: '/user/resetpw', component: ResetPwd },
  { path: '/user/agreement', component: AgreementCn },
  { path: '/auditstatus', component: AuditStatus, exact: true },
  { path: '/auditstatus/details', component: EditInfo }
]

export const APP_NAME = 'core';

export const angularModules = ['report','console', 'company', 'organization', 'quality', 'manufacture', 'partner', 'depository', 'cooperate', 'message', 'schedule'];

export const subPrjOvertime = 20000; // 子项目超时时间 