/*
 * @Author: yangzonglong
 * @Date: 2021-04-06 10:10:28
 * @version: v1.0.0
 * @Descripttion: 主文件
 * @LastEditors: yangzonglong
 * @LastEditTime: 2021-06-17 09:53:54
 * @Auditor: 
 */
import { useEffect, useState, Suspense } from 'react';
import { Route, Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import OperationTimingTip from '@components/operationTimingTip/OperationTimingTip';
import Qs from 'query-string';
import history from './history';
import { routers } from './configs';
import { getUserInToken } from './api';

const query = Qs.parseUrl(window.location.href).query,
  token = query.token, redirect = query.redirect as string | null;

const App = () => {

  const isSessionExpired = useSelector((store: any) => store.common.isSessionExpired);
  const [tokenLogin, setTokenLogin] = useState(!!token);

  useEffect(() => {
    if (token) {
      getUserInToken(token as string).then(user => {
        if (user) {
          setTokenLogin(false);
          sessionStorage.userInfo = JSON.stringify(user);
          history.push(redirect || history.location.pathname);
        }
      })
    }
  }, []);

  return (
    <>
      {!tokenLogin && (
        <>
          <Router history={history}>
            <Suspense fallback={<span />}>
              <Route exact path='/' component={routers[0].component}/>
              {routers.map(item => (
                <Route key={item.path} {...item} />
              ))}
            </Suspense>
          </Router>
          {isSessionExpired && <OperationTimingTip />}
        </>
      )}
    </>
  )
}

export default App;
