/*
 * @Author: yangzonglong
 * @Date: 2021-06-01 15:39:15
 * @version: v1.0.0
 * @Descripttion: message 提示
 * @LastEditors: yangzonglong
 * @LastEditTime: 2021-08-23 17:14:51
 * @Auditor: 
 */
import { message } from 'antd';
import Icon from '@components/icon/Icon';
import i18n from './../../i18n';
import { setQuitInquiry } from './../../utils/quitInquiry';
import style from './message.module.scss';

type ErrorProps = {
    code?: string;
    message?: string
}

message.config({ top: 2, duration: 1.5 });

const SuccessIcon = <Icon className={style.success_icon} type='success' icon='&#xe644;' />;
const ErrorIcon = <Icon className={style.error_icon} type='success' icon='&#xe645;' />;
const WarningIcon = <Icon className={style.warning_icon} type='success' icon='&#xe647;' />;

function t(k: string) {
    const text = i18n.t(`error:${k}`);
    if (text === k) return i18n.t(k);
    return text;
  }

export function success(code?: string) {
    message.success({
        content: code ? t(code) : i18n.t('success'),
        icon: SuccessIcon
    })
}

let errorTimeout: number | null = null;

export function error(code?: string, err?: ErrorProps) {
    if(errorTimeout) return;

     // eslint-disable-next-line
    if (code == '-11' || code === 'No Access Auth') {
        window.sessionStorage.clear();
        setQuitInquiry(); // 清空 QuitInquiry 函数
        return window.location.href = '/core/user/login';
    }

    let content = code ? t(code) : i18n.t('error'); // 根据code查翻译

    // 如果翻译后msg === error code, 未找到翻译使用后端message
    // code.toString() 防止部份api code返回number, 此时content为string，会无非对比到
    if(content === err?.code?.toString() && err?.message) content = err.message;

    // 兼容老的模块err未传err对象，会导出直接提示I_AGENT_MODEL_NF
    if(content === 'I_AGENT_MODEL_NF') content = '模块未订阅';

    message.error({ content, icon: ErrorIcon });

    // 避免短时内多次弹出
    errorTimeout = window.setTimeout(() => {
        errorTimeout = null;
    }, 200);
}

export function warning(code?: string) {
    if(!code) return;
    
    message.warning({ content: t(code), icon: WarningIcon })
}