/*
 * @Author: yangzonglong
 * @Date: 2021-03-25 09:34:01
 * @version: v1.0.0
 * @Descripttion: 入口文件
 * @LastEditors: yangzonglong
 * @LastEditTime: 2021-07-23 16:30:30
 * @Auditor: 
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import store from './redux/store';
import i18n from './i18n';
import App from './App';
import './utils/operationTiming';
import 'normalize.css';
import './index.scss';

import reportWebVitals from './reportWebVitals';

const root = document.getElementById('root');

const ProviderApp = () => {
  return (
    <Provider store={store}>
      <ConfigProvider locale={localStorage.i18nextLng === 'en' ? enUS : zhCN}>
        <App />
      </ConfigProvider>
    </Provider>
  )
}

i18n.init().then(() => {
  ReactDOM.render(
    <ProviderApp />,
    root
  );
}).catch(() => {
  ReactDOM.render(
    <ProviderApp />,
    root
  );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
