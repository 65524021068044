/*
 * @Author: yangzonglong
 * @Date: 2021-03-31 10:25:33
 * @version: v1.0.0
 * @Descripttion: api
 * @LastEditors: yangzonglong
 * @LastEditTime: 2021-06-16 10:44:54
 * @Auditor: 
 */
import server, { serverLoading } from '@utils/server';

// token 登录
export async function getUserInToken(token: string) {
  try {
    const user = await serverLoading('/bk/tokenLogin', { token });
    return user;
  } catch (error) {

  }
}

// 获取最新的公告
export async function getNewNotice() {
  try {
    const user = await server('/bk/api/company/User/getLoginStatus', {});

    if (!user.firstTimeLogin) return;

    const result = await server('/bk/api/console/ConsoleNotice/findOne', {
      where: { forceCheck: 1 }, order: [['createdAt', 'DESC']]
    });

    return result
  } catch (error) {
    return null
  }
}

// 读取公告 更新用户字段
export async function updateLoginStatus() {
  try {
    await serverLoading('/bk/api/company/User/changeLoginStatus', {});
    return true
  } catch (error) {
    return false
  }
}

export async function getConsoleAttachments(documentId: string | string[]) {
  try {
    const { rows } = await server('/bk/api/console/ConsoleAttachment/find',{ 
      where: { documentId }
    });
    return rows
  } catch (error) {
    return []
  }
}