/*
 * @Author: yangzonglong
 * @Date: 2021-04-27 10:00:19
 * @version: v1.0.0
 * @Descripttion: 平台通用ICON
 * @LastEditors: yangzonglong
 * @LastEditTime: 2021-06-01 15:43:40
 * @Auditor: 
 */
import React, { memo } from 'react';
import cls from './icon.module.scss';

interface IProps {
    icon: string,
    type?: 'error' | 'success',
    className?: any,
    onClick?: () => void
}

const Icon = memo(({ icon, type, className, onClick }: IProps) => {
    return (
        <span
            onClick={onClick}
            className={`${cls.iconfont} ${onClick ? cls.cursor : null} ${className} ${type ? cls[type] : null}`}>
            {icon}
        </span>
    )
})

export default Icon;