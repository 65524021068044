/*
 * @Author: yangzonglong
 * @Date: 2021-03-30 09:55:02
 * @version: v1.0.0
 * @Descripttion: 路由文件
 * @LastEditors: yangzonglong
 * @LastEditTime: 2021-03-31 09:36:42
 * @Auditor: 
 */
import { createBrowserHistory } from 'history';
import { APP_NAME } from './configs';

export default createBrowserHistory({
  basename: APP_NAME
})
