/*
 * @Author: yangzonglong
 * @Date: 2020-12-03 17:34:46
 * @version: v1.0.0
 * @Descripttion: 操作计时
 * @LastEditors: yangzonglong
 * @LastEditTime: 2021-06-25 10:08:43
 * @Auditor: 
 */
import store from '@redux/store';
import { Types } from '@redux/commonReducer';

 let timeout = null;
 const DEFAULT_TIME = 1000 * 60 * 25; // 半个小时

 export function start() {
  if(timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  timeout = setTimeout(() => { // 超时了
    if(sessionStorage.userInfo) {
      store.dispatch({ 
        type: Types.SET_IS_SESSION_EXPIRED,
        isSessionExpired: true
      })
    }
  }, DEFAULT_TIME)   

 }

 document.body.addEventListener('click', start)
